@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

.container {
  width: 41.4rem;
  border: 0.16rem solid rgba(0, 167, 106, 1);
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.wrapper {
  padding: 2rem;
}

.t1 {
  background: #00a76a;
  color: #fff;
  height: 2rem;
  font-weight: 400;
  font-size: 1.7rem;
  font-family: "Bebas Neue", cursive;
  padding: 0.5rem 0;
  text-align: center;
}

.t2 {
  font-family: "Bebas Neue", cursive;
  font-size: 1.5rem;
  font-size: 400;
  color: #fff;
  padding-bottom: 2rem;
  text-align: center;
}

/* input {
  padding: 1rem;
  background: #1a1a1a;
  border: none;
  font-size: 1.1rem;
  color: #fff;
  outline: none;
} */

/* input[type="email"],
input[type="password"] {
  width: 30rem;
  padding-left: 4rem;
  background-color: #1a1a1a;
} */

.focused {
  outline: 0.19rem solid #00a76a;
  box-shadow: 0px 0px 10px 1px rgba(0, 167, 106, 0.5);
}

/* input::placeholder {
  color: #717171;
  font-size: 1.1rem;
  font-weight: 400;
} */

.form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.label1 {
  display: flex;
  align-items: center;
  position: relative;
}

.profile1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 20px;
}

.logInBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: #00a76a;
  color: #fff;
  border: none;
  height: 3.5rem;
  font-weight: 700;
  font-size: 1.4rem;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  transition: 0.4s ease all;
}

.logInBtn:hover {
  box-shadow: 0px 0px 10px 1px rgba(0, 167, 106, 0.5);
}

.logInBtn:disabled {
  opacity: 0.5;
}
.imgLoading {
  height: 1.6rem;
  width: 1.6rem;
}

.forgotPasswordContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  & > * {
    color: #00a76a;
    text-decoration: none;
  }
}

.p1 {
  color: #fff;
  text-align: center;
}

.l1 {
  color: #00a76a;
  padding-left: 0.1rem;
  text-decoration: none;
}

@media only screen and (max-width: 646px) {
  .container {
    width: 23rem;
  }
  .form {
    padding: 0;
  }
  /* input[type="email"],
  input[type="password"] {
    width: 14rem;
  } */
}


