@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

.container {
  border: 0.16rem solid rgba(0, 167, 106, 1);
  border-radius: 10px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  transition: all 0.5s ease;
}

.select_custom {
  background-color: #151515;
  font-family: "Bebas Neue" !important;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3rem;
  width: 26.5vw;
}

.option {
  background-color: red;
  color: #fff;
  font-size: 1.1rem;
}

.option:hover {
  background-color: #00a76a;
}

.menuClose {
  margin-left: 15vw;
  width: 70vw;
}

.menuOpen {
  width: 70vw;
  margin-left: 22vw;
}

.wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #212121;
}

/* .customSelect {
  width: 32rem;
  background-color: #151515;
  padding: 1rem;
  color: #fff;
  border: none;
  font-size: 1.2rem;
  font-weight: 700;
} */

.t1 {
  /* background: #00a76a; */
  background: none;
  color: #00a76a !important;
  border-bottom: 2px solid #00a76a;
  color: #fff;
  height: 5rem;
  font-weight: 400;
  font-size: 40px;
  font-family: "Bebas Neue" !important;
  padding: 0.5rem 0;
  padding-left: 40px;
}

.t2 {
  font-family: "Bebas Neue" !important;
  font-size: 1.5rem;
  font-size: 400;
  color: #fff;
  padding-bottom: 2rem;
  text-align: center;
}

.t3 {
  font-family: "standerd" !important;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 22px;
  color: #b4b4b4;
  padding: 0;
  margin: 0;
  max-width: 50%;
  flex-grow: 0;
  display: block;
}

.t4 {
  width: 17vw;
  line-height: 2rem;
}

/* input {
  padding: 1rem;
  background: #1a1a1a;
  border: none;
  font-size: 1.1rem;
  color: #fff;
} */

.labelFile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  background-color: #151515;
  color: #00a76a;
  cursor: pointer;
  padding: 0.8rem 1.8rem;
  font-size: 1.1rem;
  width: 6rem;
  border: 1px solid #00a76a;
}

/* .labelFile:hover {
  color: #00a76a;
  background-color: #fff;
} */

.labelArea {
  display: flex;
  position: relative;
  align-items: center;
  gap: 3rem;
  color: #fff;
  font-weight: 700;
  font-size: 1.1rem;
}

/* input[name="building_name"],
input[name="city_name"],
input[name="area"],
input[name="floor"],
input[name="unit_number"],
input[name="asset_type"],
input[name="size_of_asset_sqft"],
input[name="service_charges_sqft"],
input[name="developer_marketing_material"],
input[name="recommended_price"],
input[name="rent_value"],
input[name="extra_amenities"] {
  width: 25vw;
  color: #fff;
  font-weight: 700;
  font-size: 1.1rem;
  background: #151515;
}
input[name="extra_amenities"]:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

input[name="developer_marketing_material"]:disabled {
  cursor: not-allowed;
  opacity: 0.5;
} */

textarea[name="comment"] {
  padding: 1rem;
  background: #1a1a1a;
  border: none;
  font-size: 1.1rem;
  color: #fff;
  width: 25vw;
  height: 6vw;
  border: none;
  font-weight: 700;
  background: #151515;
  resize: none;
}

/* .select {
  width: 26.5vw;
  outline: none;
} */

input[type="file"] {
  display: none;
}

/* input[name="building_name"]::placeholder,
input[name="city_name"]::placeholder,
input[name="area"]::placeholder,
input[name="unit_number"]::placeholder,
input[name="asset_type"]::placeholder,
input[name="floor"]::placeholder,
input[name="service_chargers_sqft"]::placeholder,
input[name="size_of_asset_sqft"]::placeholder,
input[name="developer_marketing_material"]::placeholder,
input[name="recommended_price"]::placeholder,
input[name="rent_value"]::placeholder,
input[name="extra_amenities"]::placeholder {
  color: #717171;
  font-size: 1.1rem;
  font-weight: 700;
} */

.form {
  width: 98.5%;
  display: flex;
  flex-direction: column;
}

.formSectionTitle {
  font-weight: 100;
  font-family: "Bebas Neue" !important;
  font-size: 23px;
  color: white;
  margin: 0;
  margin-bottom: 15px;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 43px;
}

.label1 {
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  gap: 3rem;
  font-weight: 700;
  font-size: 1.1rem;

  & > div {
    width: 100%;
  }
}

.label2 {
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.span1 {
  color: #00a76a;
}

.profile1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  font-size: 2rem;
}

.PropertySubmitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: #00a76a;
  color: #fff;
  width: 250px;
  border: none;
  height: 60px;
  font-weight: 700;
  font-size: 1.4rem;
  cursor: pointer;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 100;
  transition: 0.4s ease all;
  margin: 0 auto;
  margin-top: 1rem;
}

.PropertySubmitBtn:hover {
  box-shadow: 0px 0px 10px 0.1rem rgba(0, 167, 106, 0.5);
}

.PropertySubmitBtn:disabled {
  opacity: 0.5;
}

.forgetLink {
  text-align: end;
  color: #fff;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.p1 {
  color: #fff;
  text-align: center;
}

.l1 {
  color: #00a76a;
  padding-left: 0.1rem;
  text-decoration: none;
}

/* .selectAsset {
  width: 32rem;
  background: #151515;
  border: none;
  color: #;
  font-weight: 700;
  height: 3rem;
  font-size: 1.2rem;
  padding-left: 1rem;
}

.optionAsset {
  font-size: 1.2rem;
} */

/* Toggle  */
.toggleButton {
  border: 0.15rem solid #717171;
  position: relative;
  width: 4.8rem;
  height: 2.76rem;
  border-radius: 3rem;
  background-color: #151515;
  cursor: pointer;
}

.toggleButton:before {
  content: "";
  position: absolute;
  top: 0.2rem;
  left: 0.15rem;
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 50%;
  background-color: #717171;
  transition: transform 0.2s ease;
}

.toggleButton.on:before {
  transform: translateX(2.15rem);
  background-color: #00a76a;
}

/* @keyframes toggle {
  0% {
    background-color: #151515;
  }
  50% {
    background-color: #151515;
  }
  100% {
    background-color: #151515;
  }
} */

.toggleButton.on {
  animation: toggle 0.3s linear;
  border: 0.15rem solid #00a76a;
}

.toggleButton.off {
  animation: toggle 0.3s linear reverse;
}

.uploadedList {
  background-color: #151515;
  padding: 1rem;
  list-style-type: none;
  /* padding-left: 0; */
  font-size: 1.1rem;
  font-weight: 700;
  width: 25vw;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trash {
  cursor: pointer;
}

@media only screen and (max-width: 777px) {
  .container {
    /* margin-left: 5.5rem; */
    margin-top: 0;
  }
  .menuClose {
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
    margin-bottom: 5rem;
  }

  .menuOpen {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
  }
  .label1,
  .labelArea,
  .label2 {
    font-size: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .t3,
  .t4 {
    width: auto;
  }

  /* .labelFile {
    font-size: 1rem;
    width: 6rem;
    padding: 0.4rem 1rem;
  } */

  .uploadIcon {
    width: 1rem;
    height: 1rem;
  }

  .PropertySubmitBtn {
    width: 52vw;
    height: 10vw;
    font-size: 1.3rem;
  }

  .toggleButton {
    width: 4.8rem;
    height: 3rem;
  }

  .toggleButton:before {
    width: 2rem;
    height: 2.27rem;
  }
  .toggleButton.on:before {
    transform: translateX(2rem);
    background-color: #00a76a;
  }

  /* .select {
    width: 66vw;
    background-color: #151515;
    font-size: 1rem;
  } */

  .form {
    gap: 1.2rem;
  }

  input[name="building_name"],
  input[name="city_name"],
  input[name="area"],
  input[name="floor"],
  input[name="unit_number"],
  input[name="asset_type"],
  input[name="size_of_asset_sqft"],
  input[name="service_chargers_sqft"],
  input[name="developer_marketing_material"],
  input[name="recommended_price"],
  input[name="rent_value"],
  textarea[name="comment"],
  .uploadedList {
    font-size: 1rem;
    width: 60vw;
    font-weight: 500;
  }

  input[name="building_name"]::placeholder,
  input[name="city_name"]::placeholder,
  input[name="area"]::placeholder,
  input[name="unit_number"]::placeholder,
  input[name="asset_type"]::placeholder,
  input[name="floor"]::placeholder,
  input[name="service_chargers_sqft"]::placeholder,
  input[name="size_of_asset_sqft"]::placeholder,
  input[name="developer_marketing_material"]::placeholder,
  input[name="recommended_price"]::placeholder,
  input[name="rent_value"]::placeholder {
    font-size: 1rem;
    font-weight: 500;
  }
}
