.generate_link_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #151515;
  color: #00a76a;
  cursor: pointer;
  width: 7rem;
  height: 2.5rem;
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: 700;
  border: 0.18rem solid #00a76a;
}

.generate_link_btn:disabled {
  opacity: 0.6;
  background-color: #151515;
  color: #00a76a;
  cursor: pointer;
  padding: 0.3rem 0.8rem;
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: 700;
  border: 0.18rem solid #00a76a;
}
.imgLoading {
  width: 1rem;
}
