@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

.customSelect {
  position: relative;
  width: 200px;
}
.selectedOption {
  width: 16rem;
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;
  background: #151515;
  font-family: "Bebas Neue";
  height: 3rem;
  /* padding: 5px; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.optionTitle {
  padding-left: 1rem;
  margin: auto 0;
}

.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #ffffff transparent transparent transparent;
  margin: auto 1rem;
}
.up {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #fff transparent;
}
.options {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  background: #151515;
  width: 16rem;
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;
  font-family: "Bebas Neue";
}
.options li {
  padding: 1rem 0;
  padding-left: 1rem;
  cursor: pointer;
}
.options li:hover {
  background-color: #00a76a;
  color: #fff;
  /* opacity: 0.3; */
}
