@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

.list {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3rem;
  transition: all 0.5s ease;
  width: 100vw;
}

.card_list {
  width: 90vw;
}

@media only screen and (max-width: 700px) {
  .list {
    /* width: 80vw; */
    margin-bottom: 5rem;
  }

  .card_list {
    width: 90vw;
  }
}
