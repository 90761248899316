@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

html {
  overflow-x: hidden;
}



.header {
  position: relative;
  width: 94%;
  padding-left: 5rem;
  padding-right: 5rem;
  top: 0;
  left: 0;
  transition: 0.3s ease-in;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 7rem;
  font-weight: 500;
  background-color: #212121;
}

.nav {
  display: flex;
  align-items: center;
}
.nav a {
  margin-right: 2rem;
  color: #fff;
  font-size: 1.4rem;
  text-decoration: none;
  font-family: "Bebas Neue";
  transition: all 0.5;
}

.logo {
  width: 14rem;
  height: 14rem;
}

.contactBtn {
  border: 0.2rem solid #00a76a;
  color: #00a76a;
  font-size: 1.2rem;
  font-family: "Bebas Neue";
  padding: 0.2rem 2rem;
  background: transparent;
  margin-left: 2rem;
  cursor: pointer;
}

.SignInBtn {
  /* border: 0.2rem solid #00a76a; */
  border: none;
  position: absolute;
  top: 44px;
  right: 0px;
  color: #fff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  /* padding: 0.5rem; */
  gap: 0.5rem;
  z-index: 2;
  background: transparent;
  /* padding: 0.2rem 2rem; */
  /* background: #00a76a; */
  cursor: pointer;
  padding-left: 1.2rem;
  background-color: #151515;
  width: 12rem;
  height: 3rem;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.LogInBtn {
  border: none;
  color: #fff;
  font-size: 1.2rem;
  font-family: "Bebas Neue";
  padding: 0.2rem 2rem;
  background: #00a76a;
  cursor: pointer;
}

.nav a:hover {
  color: #00a76a;
}

.header .navBtn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-size: 1.4rem;
}

.user_info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #fff;
}

.userBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.kyc_unverified {
  padding-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  color: #ff1b44;
}

.kyc_verified {
  padding-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  color: #00a76a;
}

.profile_icon {
  cursor: pointer;
  width: 3.5rem;
  margin-right: 8px;
  border: 2px solid #00a76a;
  border-radius: 100px;
}

.profile_user {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  gap: 0.5rem;
  height: 3rem;
}

.profile_userOpen {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  gap: 0.5rem;
  height: 3rem;
}

.logout_drop {
  margin-left: 0;
}

.arrowIcon {
  width: 1rem;
}

.css {
  flex: 1 1;
}

@media only screen and (max-width: 450px) {
}

@media only screen and (max-width: 1024px) {
  .header {
    width: 100%;
    margin-left: 0;
    padding: 0;
    margin-bottom: 0;
  }
  .css {
    flex: none;
  }
  .header .navBtn {
    visibility: visible;

    opacity: 1;
  }

  .header .nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 30rem;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #151515;
    transition: 1s;
    transform: translateY(-100vh);
    z-index: 100;
    overflow-x: hidden;
  }

  .contactBtn {
    margin-left: 0;
    border: 0.1rem solid #00a76a;
    color: #00a76a;
    font-size: 1.1rem;
    font-family: "Bebas Neue";
    padding: 0.2rem 2rem;
  }

  .header .responsive_nav {
    transform: none;
  }

  .nav .navCloseBtn {
    position: absolute;
    top: 2.5rem;
    right: 1.5rem;
  }

  .nav a {
    margin-right: 0;
    font-size: 1rem;
  }
}
