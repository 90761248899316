@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

.tableContainer {
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: #1a1a1a;
  height: 60rem;
  transition: all 0.5s ease;
  overflow-x: auto;
}

.menuClose {
  margin-left: 15vw;
  width: 75vw;
}

.menuOpen {
  width: 70vw;
  margin-left: 22vw;
}

.table {
  border-collapse: collapse;
  width: 100%;
  position: relative;
}

.head {
  background-color: #00a76a;
  color: #fff;
  padding: 3rem;
}

.td_username {
  width: 19rem;
  padding: 0;
}

.td_link {
  color: #717171;
  font-size: 1.1rem;
}

th,
td {
  text-align: left;
  padding: 1.1rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.1rem;
  font-family: "Open Sans";
}

th {
  background-color: #00a76a;
}

th:last-child {
  text-align: center;
}

td:last-child {
  display: flex;
  justify-content: center;
}

.link {
  color: #fff;
  text-decoration: none;
}

input[name="username"] {
  background-color: #151515;
  font-weight: 700;
  font-size: 1.1rem;
  font-family: "Open Sans";
  padding-left: 1.2rem;
  outline: none;
  border: none;
  color: white;
}
input[name="username"]::placeholder {
  color: #717171;
  font-weight: 700;
  font-size: 1.1rem;
  padding: 0;
  font-family: "Open Sans";
}

.add_link_btn_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.view_button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.view_button:hover {
  opacity: 0.6;
}

.linkField {
  color: #717171;
  font-weight: "700";
  font-size: "0.2rem";
}

.errMessage {
  color: red;
  font-size: 2rem;
  background-color: red;
  z-index: 100;
}

.loading {
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
}
/* tr:hover {
  background-color: #ddd;
}

.selected {
  background-color: #ffb6c1 !important;
} */

@media only screen and (max-width: 768px) {
  .tableContainer {
    /* width: 100%; */
    margin-top: 0;
  }
  th,
  td {
    padding: 1rem;
    font-size: 0.9rem;
  }
  .td_username {
    width: 9rem;
    font-size: 1rem;
  }
  input[name="username"] {
    font-size: 1rem;
    width: 7rem;
  }
  input[name="username"]::placeholder {
    font-size: 1rem;
    /* width: auto; */
  }

  .add_link_btn_container {
    justify-content: flex-start;
  }

  .linkField {
    font-size: 1rem;
  }

  .td_link {
    color: #717171;
    font-size: 0.8rem;
  }

  .menuClose {
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
    margin-bottom: 5rem;
  }

  .menuOpen {
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
    margin-bottom: 5rem;
  }
}
