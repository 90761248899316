/* @font-face {
  font-family: Standerd;
  src: url("./fonts/Standerd-Regular.otf");
} */

@font-face {
  font-family: Standerd;
  src: url("./assets/fonts/Standerd/Standerd-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: Standerd;
  src: url("./assets/fonts/Standerd/Standerd-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: Standerd;
  src: url("./assets/fonts/Standerd/Standerd-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Standerd;
  src: url("./assets/fonts/Standerd/Standerd-Medium.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Standerd;
  src: url("./assets/fonts/Standerd/Standerd-SemiBold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Standerd;
  src: url("./assets/fonts/Standerd/Standerd-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Standerd;
  src: url("./assets/fonts/Standerd/Standerd-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Standerd;
  src: url("./assets/fonts/Standerd/Standerd-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: Standerd;
  src: url("./assets/fonts/Standerd/Standerd-Thin.ttf");
  font-weight: 100;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212121 !important;
}

html {
  /* For WebKit browsers */
  ::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* Remove the background */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00a76a; /* Set the color of the thumb */
    border-radius: 10px; /* Rounded corners */
  }

  /* For Firefox */
  ::-moz-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  ::-moz-scrollbar-track {
    background: transparent; /* Remove the background */
  }

  ::-moz-scrollbar-thumb {
    background-color: #00a76a; /* Set the color of the thumb */
    border-radius: 10px; /* Rounded corners */
  }

  /* For IE and Edge */
  ::-ms-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  ::-ms-scrollbar-track {
    background: transparent; /* Remove the background */
  }

  ::-ms-scrollbar-thumb {
    background-color: #00a76a; /* Set the color of the thumb */
    border-radius: 10px; /* Rounded corners */
  }
}

.reactSelect__control {
  width: 100% !important;
  flex-grow: 1;
  padding: 0 7px;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #4e4e4e;
  padding-top: 10px;
  min-height: 65px !important;
  font-size: 22px;
  font-family: "standerd" !important;
  text-transform: uppercase;
  font-weight: 100;
  cursor: pointer !important;
}

.reactSelect__placeholder {
  color: rgba(180, 180, 180, 0.35) !important;
  font-size: 22px;
}

.reactSelect__input::placeholder {
  color: rgba(180, 180, 180, 0.35) !important;
}

.reactSelect__menu {
  display: flex;
  background-color: #212121;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
}

.reactSelect__menu-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 15px; */
}

.reactSelect__dropdown-indicator svg {
  display: none !important;
}

.reactSelect__dropdown-indicator::after {
  height: 15px;
  width: 15px;
  content: "";
  background: url("./assets/dropdownArrowDown.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.reactSelect__option {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* text-align: center; */
  padding: 7px;
  border-bottom: 1px solid #00a76a25;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
}

.reactSelect__option:last-child {
  border-bottom: none;
  margin-bottom: 8px;
}

.reactSelect__option:hover {
  background-color: #00a76a;
  transition: all cubic-bezier(1, 0, 0, 1) 0.2s;
}

.reactSelect__menu-list::-webkit-scrollbar {
  width: 0.25rem;
}

.reactSelect__menu-list::-webkit-scrollbar-track {
  background: #212121;
}

.reactSelect__menu-list::-webkit-scrollbar-thumb {
  background: #00a76a;
}

.reactSelect__menu-list::-webkit-scrollbar-thumb:hover {
  background: #00a76a;
}

.reactSelect__placeholder {
  color: #fff;
}

.reactSelect__multi-value {
  border: 1px solid #00a76a;
  background-color: transparent;
  font-family: "Standerd" !important;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 2px 12px 8px 2px;
}

.antd-dropzone {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: #00a76a;
  font-family: "Bebas Neue" !important;

  & span {
    font-size: 18px;
    font-weight: 100;
  }
}

.ant-upload-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(535px, 1fr));
  grid-gap: 10px;
  /* justify-content: space-between; */
}

.ant-upload-list::after,
.ant-upload-list::before {
  position: absolute;
}

.ant-upload-list-item-container {
  width: 530px;
  max-width: 87.5%;
  color: white;
}

.ant-upload-list-item {
  border: none !important;
  background-color: #242424;
}
:where(.css-dev-only-do-not-override-zjzpde).ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action.ant-btn-sm
  > span {
  display: none;
}

:where(.css-dev-only-do-not-override-zjzpde).ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action:focus,
:where(.css-dev-only-do-not-override-zjzpde).ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions.picture
  .ant-upload-list-item-action::after {
  content: "✕";
  color: red;
  font-size: 20px;
}

.iframe2 {
  min-height: 300px;
}
