.container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #151515;
  color: #fff;
  z-index: 20;
}

.sideMap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  height: 70px;
}

.link {
  padding: 10px;
}

.active {
  padding: 10px;
  border-radius: 3px;
  background: #2b463c;
}
