.local-bootstrap {
  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 3px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .dropup .caret,
  .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
  }

  .sr-only {
    display: none;
  }

  .nav-pills {
    font-size: 30px;
    .active {
      background-color: rgb(0, 167, 106) !important;
    }
  }
  .form-select:disabled {
    background-color: #555;
    border-color: #555;
    color: #777;
  }
}

.adminPropertyInput {
  font-weight: 100 !important;
  font-size: 30 !important;
}

.antd-theme {
  a {
    color: #00a76a;
  }

  a:hover {
    color: #017047;
  }
}

.ant-table-thead .ant-table-cell {
  border-radius: 0 !important;
  background-color: #00a76a !important;
}

.ant-table-wrapper .ant-table-column-sorter {
  color: rgba(255, 255, 255, 0.58) !important;
}

.ant-table-wrapper .ant-table-column-sorter-up.active {
  color: #00a76a !important;
}

.ant-table-wrapper .ant-table-column-sorter-down.active {
  color: #00a76a !important;
}

.ant-table-wrapper .ant-table-filter-trigger {
  color: white;
}

.ant-btn-primary {
  background-color: #00a76a;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: #6ee9bc;
}

.ant-input:focus {
  border-color: #00a76a;
}

.ant-btn-link {
  color: #00a76a;
}

.ant-btn-link:not(:disabled):hover {
  color: #017047;
}
.ant-btn-link:not(:disabled):active {
  color: #017047;
}

.adminPropertyInput {
  font-weight: 100 !important;
  font-family: "Open Sans", sans-serif !important;
}

.adminPropertyInputSelect {
  color: black !important;
}

.adminPropertySwitch {
  border-radius: 100px !important;
  transform: translateX(20px) scale(2);
}

.ant-table-cell {
  display: table-cell !important;
}

.paymentModal {
  font-family: "Bebas Neue" !important;
  .ant-modal-content {
    border: 1px solid #00a76a;
    border-radius: 0;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #00a76a;
    border-color: #00a76a;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #017047;
    border-color: #017047;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #00a76a;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #00a76a;
  }

  .ant-modal-close {
    display: none;
  }

  .text-green {
    color: #00a76a;
  }

  .selectPayment {
    background-color: #151515;
    color: white;
    border: 0;
    padding: 8px;
    width: 50%;
  }

  .border-green {
    border-color: #00a76a !important;
  }
  .ant-modal-title {
    font-size: 30px;
    font-weight: 100;
  }

  .ant-modal-body {
    .ant-btn {
      font-family: "Bebas Neue" !important;
      border-radius: 0;
    }
  }

  .ant-select-selector {
    border: 0;
  }
  .ant-select-focused {
    border: 0;
  }

  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

  .openSans {
    font-family: "Open Sans", sans-serif !important;
  }

  .buttonConfirmPayment:disabled {
    background-color: #017047ab;
    color: #ffffff61 !important;
  }
}
.ant-table-cell {
  .btn-outline-success button:disabled {
    background-color: transparent !important;
    border-color: transparent;
    color: #00a76a74;
  }
  .btn-outline-danger button:disabled {
    background-color: transparent !important;
    border-color: transparent;
    color: #a7000074;
  }
}
