@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

.container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.main {
  width: 100%;
}

.sidebar {
  height: 100vh;
  background-color: #282828;
  float: left;
  z-index: 1;
  position: fixed;
  transition: transform 0.5s ease-in-out;
}

.topSection {
  display: flex;
  justify-content: center;
  height: 7rem;
}

.Logo {
  width: 14rem;
  height: 7rem;
  cursor: pointer;
}

.section2 {
  background: #151515;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  color: #212121;
  font-size: 2rem;
  font-weight: 700;
  white-space: nowrap;
  font-family: "Bebas Neue";
}

.SmSection2 {
  padding: 1rem 0.5rem;
  font-size: 2.1rem;
  background: #151515;
  display: flex;
  align-items: center;
  color: #212121;
  font-weight: 700;
  font-family: "Bebas Neue";
}

.sideContainer {
  display: flex;
  flex-direction: column;
  min-height: 46rem;
  white-space: nowrap;
}
.sideMap {
  position: relative;
  display: flex;
  flex-direction: column;
}

.link {
  text-decoration: none;
  font-size: 16px;
  display: flex;
  color: #fff;
  padding: 1.5rem;
  gap: 1.5rem;
  transition: all 0.5;
  color: #8d8d8d;
}

.linkText {
  font-weight: 600;
}

.icon {
  display: flex;
  justify-content: center;
}

/* .link:hover {
  background: #2b463c;
  transition: all 0.5s;
  color: #00a76a;
} */

.active {
  text-decoration: none;
  font-size: 16px;
  display: flex;

  padding: 1.5rem;
  gap: 1.5rem;
  transition: all 0.5;

  color: #00a76a;
}

.SmallLogo {
  height: 4.7rem;
  width: 3.3rem;
  cursor: pointer;
}
