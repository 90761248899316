.mostViewed {
  width: 78vw;
  display: flex;
  align-items: center;
  gap: 5rem;
}

.category_container {
  background-color: #151515;
  display: flex;
  align-items: center;
  width: 3rem;
  height: 3rem;
}

.category_icon {
  margin: auto;
}

@media only screen and (max-width: 700px) {
  .mostViewed {
    width: 72vw;
    gap: 3rem;
  }
}
