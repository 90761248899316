@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");

.container {
  position: relative;
  top: 5px;
  width: 41.4rem;
  border: 0.16rem solid rgba(0, 167, 106, 1);
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  box-shadow: 0px 0px 56px 0px #01573863;
  border-radius: 10px;
}

.wrapper {
  padding: 2rem;
}

.t1 {
  background: none;
  border-bottom: 1px solid #00a76a;
  padding: 20px;
  color: #fff;
  font-weight: 400;
  font-size: 35px;
  font-family: "Bebas Neue" !important;
  text-align: center;
}

.t2 {
  font-family: "Bebas Neue" !important;
  font-size: 28px;
  font-size: 400;
  color: #fff;
  padding-bottom: 1rem;
  text-align: center;
}

.t3 {
  font-family: "Bebas Neue" !important;
  font-size: 20px;
  font-size: 400;
  color: #fff;
  padding-bottom: 1rem;
  text-align: center;
}

/* box-shadow: 0px 0px 10px 1px rgba(0, 167, 106, 0.5); */

.r1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  gap: 3rem;
  position: relative;
}

.focused {
  outline: 0.19rem solid #00a76a;
  box-shadow: 0px 0px 10px 1px rgba(0, 167, 106, 0.5);
}

.privacyBtn {
  text-align: start;
  color: #717171;
}

.c1 {
  margin: 0;
  width: 11.831rem;
  height: 11.86rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.19rem solid rgba(0, 167, 106, 1);
  position: relative;
}

.c11 {
  margin: 0;
  width: 11.831rem;
  height: 11.86rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.19rem solid rgba(0, 167, 106, 1);
  background: #00a76a;
  box-shadow: 0px 0px 10px 1px rgba(0, 167, 106, 0.5);
  position: relative;
}

.icon1,
.icon2 {
  width: 67.4px;
  height: 65.04px;
}

.btn1 {
  position: absolute;
  bottom: 0;
  border: none;
  background-color: #00a76a;
  width: 12rem;
  cursor: pointer;
  color: #fff;
  font-family: "Bebas Neue" !important;
  font-size: 1.2rem;
  font-weight: 400;
}

.span {
  color: #fff;
  font-family: "Bebas Neue" !important;
  font-size: 1.5rem;
  font-weight: 400;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.label1 {
  display: flex;
  align-items: center;
  position: relative;
}

.profile1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 20px;
}

.profile2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
  font-size: 20px;
}

.f1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lastName {
  margin-left: 1rem;
}

.btnCreate {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: #00a76a;
  color: #fff;
  border: none;
  height: 3.5rem;
  font-weight: 400;
  /* padding: 18px 0; */
  font-size: 22px;
  font-family: "Bebas Neue" !important;
  cursor: pointer;
  transition: 0.4s ease all;
}

.btnCreate:hover:not(:disabled) {
  box-shadow: 0px 0px 10px 1px rgba(0, 167, 106, 0.5);
}

.btnCreate:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.p1 {
  color: #fff;
  text-align: center;
}

.imgLoading {
  height: 1.6rem;
  width: 1.6rem;
}

.l1 {
  color: #00a76a;
  padding-left: 0.1rem;
  text-decoration: none;
}

@media only screen and (max-width: 646px) {
  .container {
    width: 23rem;
  }
  .c11,
  .c1 {
    width: 6.831rem;
    height: 8.86rem;
  }
  .btn1 {
    width: 7rem;
  }

  .r1 {
    gap: 2rem;
  }

  .form {
    padding: 0;
  }

  .icon1,
  .icon2 {
    position: absolute;
    top: 23px;
  }

  .f1 {
    flex-direction: column;
    gap: 1rem;
  }

  .lastName {
    margin-left: 0;
  }

  .profile2 {
    left: 10px;
  }
}

.formGroup {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 64px; */
  height: 60px;
  padding-top: 10px;
  border: unset;

  font-family: "standerd" !important;
  text-transform: uppercase;
  border-bottom: 1px solid #4e4e4e;

  &:hover {
    border-bottom: 1px solid #cccccc;
  }

  &:has(.customInput[type="email"]:invalid) {
    border-bottom: 1px solid #c43840;
  }

  &.filled {
    border-bottom: 1px solid #00a76a;
  }
}

.customInput {
  height: 60px;
  background-color: transparent;
  border: unset;
  font-size: 22px;
  font-family: "Standerd" !important;
  font-weight: 600;
  color: white;
  padding-left: 5px;

  &:focus {
    outline: none;
  }
}

.passwordIcon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 35px;
  transform: translateY(-50%) scale(1);
}

.formGroup:focus-within label {
  transform: scale(0.5);
  color: rgba(180, 180, 180, 1);
  top: 0;
}

.formGroup label.filled {
  transform: scale(0.5);
  color: #b4b4b4;
  top: 0;
}

.formGroup label {
  position: absolute;
  pointer-events: none;
  /* transform: translate(0, 23px) scale(1); */
  transform: translateY(-50%) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: rgba(180, 180, 180, 0.35);
  font-size: 22px;
  line-height: 1;
  left: 5px;
  top: 35px;
}

.privacyContainer {
  color: white;
  margin-top: 20px;
  cursor: pointer;
  user-select: none;

  & > input {
    /* change accent color */
    accent-color: #00a76a;
    /* padding: 1px; */
    width: 14px;
    height: 14px;
    margin-right: 15px;

    &:active {
      accent-color: #00a76a !important;
    }

    &:hover {
      accent-color: #00a76a !important;
    }
  }
}

.orLogIn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 20px;
  cursor: pointer;
  user-select: none;
  /* gap: 3px; */

  & .green {
    color: #00a76a;
    cursor: pointer;
  }
}
