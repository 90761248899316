.overlay {
  background: rgba(109, 109, 109, 0.74);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.container {
  width: 73.4vw;
  background: #151515;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.section1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3vw;
}

.box {
  width: 16vw;
  height: 16vw;
  background: #00a76a;
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
  gap: 2vw;
  font-size: 1.7vw;
  font-family: "Bebas Neue";
  color: #fff;
  margin: auto;
  padding-left: 3rem;
  padding-right: 3rem;
}

.value {
  height: 4vw;
  background-color: #085b3d;
  width: 16vw;
  font-size: 1.3vw;
  color: #fff;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 2.5vw;
}

.th {
  border-left: none;
  width: 10vw;
  text-align: left;
  padding: 1.1rem;
  color: #fff;
  background-color: #00a76a;
  font-weight: 600;
  font-size: 1.1vw;
  font-family: "Open Sans";
}

.tr_view {
  font-size: 1.1vw;
  border-right: 2px solid #00a76a;
  border-left: 2px solid #00a76a;
  border-bottom: 2px solid #00a76a;
}

@media only screen and (max-width: 1200px) {
  .title {
    padding-left: 0;
    padding-right: 0;
  }
}
