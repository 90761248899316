@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

.container {
  /* padding-left: 13rem; */
  display: flex;
}

.cardList {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin: 0.4rem;
  text-decoration: none;
  transition-duration: 0.3s;
}

.cardList:hover {
  box-shadow: 0 0 56px 0px #01573863;
  transition-duration: 0.3s;
}

.menuClose {
  /* margin-left: 5rem; */
  /* width: 100%; */
}

.menuOpen {
  /* width: 95%; */
  /* margin-left: 15rem; */
}

.icon_location {
  object-fit: contain;
}

.wrapper {
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}



/* .loading {
  height: 20rem;
  width: 20rem;
  background-color: #111111;
} */

.details_container {
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #fff;
  font-family: "Bebas Neue";
}

.details {
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
}

.location {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
  font-weight: 100 !important;
}

.icon2 {
  width: 0.8rem;
  /* height: 0.4rem; */
}

.load_more_btn {
  width: 40rem;
  /* margin: auto; */
}

@media only screen and (max-width: 700px) {
  .wrapper {
    flex-direction: column;
  }
  .icon_location {
    height: 18rem;
  }
}


.title
{
  font-size: 2rem;
  padding-top: 20px;
}