.faqaccordion {
  .local-bootstrap button {
    font-family: "OpenSans", sans-serif !important;
  }
  .accordion-item {
    margin: 5px;
    border-radius: 0 !important;
    background-color: #151515;
    color: white !important;
    border: 0;
  }
  .accordion-button {
    border-radius: 0 !important;
    background-color: #151515;
    color: white !important;
    box-shadow: none !important;
  }
  .accordion-button:not(.collapsed) {
    border-radius: 0 !important;
    background-color: #151515;
    color: white !important;
  }
  .accordion-item:has(div.show) {
    border: 2px solid #00a76a !important;
  }
  .accordion-item:has(div.collapsing) {
    border: 2px solid #00a76a !important;
  }
  .accordion-header button {
    font-weight: 700 !important;
  }

  .accordion-button:focus {
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/arrowaccordion.svg");
  }
}

.stepsprocesspart {
  background-image: url("../../assets/stepsbg.png");
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: contain;
  width: 300px !important;
  height: 300px;
  padding: 0 !important;
  margin: 0 !important;
}

.stepsprocesspart .holder {
  position: relative;
  top: 15px;
  left: 0px;
  display: flex;
}

.stepsprocesspart .number {
  color: #212121;
  font-size: 50px;
  border: 5px #212121 solid;
  background-color: #00a76a;
  border-radius: 100px !important;
  height: 100px !important;
  width: 100px !important;
  text-align: center;
  line-height: 90px;
}

.stepsprocesspart .text {
  font-size: 30px;
  color: white;
  line-height: 50px;
  position: relative;
  left: 20px;
  border-radius: 100px !important;
  height: 100px !important;
  width: 150px !important;
}
