.kyc_unverified {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  color: #ff1b44;
}

.kyc_verified {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  color: #00a76a;
}
