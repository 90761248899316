@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

.searchBar {
  width: 90%;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
}

input[name="searchBar"] {
  width: 34vw;
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;
  background: #151515;
  border: 0.17rem solid #00a76a;
  outline: none;
  padding-left: 4rem;
}

input[name="searchBar"]::placeholder {
  color: #717171;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: "Bebas Neue" !important;
}

.label {
  position: relative;
}

.search_icon {
  /* width: 1.8rem;
  height: 1.8rem; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
}

@media only screen and (max-width: 700px) {
  input[name="searchBar"] {
    width: 56vw;
    color: #fff;
    font-weight: 700;
    font-size: 1rem;
    background: #151515;
    border: 0.17rem solid #00a76a;
    outline: none;
    padding-left: 4rem;
  }
  input[name="searchBar"]::placeholder {
    color: #717171;
    font-size: 1rem;
    font-weight: 700;
    font-family: "Bebas Neue" !important;
  }
}
