@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

.container {
  border: 0.16rem solid rgba(0, 167, 106, 1);
  margin-top: 4.5rem;
  margin-bottom: 3rem;
  transition: all 0.5s ease;
  background-color: #1a1a1a;
}

.menuClose {
  margin-left: 14vw;
  width: 70vw;
}

.menuOpen {
  width: 70vw;
  margin-left: 22vw;
}

.t1 {
  background: #00a76a;
  color: #fff;
  height: 2vw;
  font-weight: 400;
  font-size: 1.6vw;
  font-family: "Bebas Neue" !important;
  padding: 0.25rem 0;
  padding-left: 0.77vw;
}

.wrapper {
  padding: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2vw;
}

.building_image {
  width: 15vw;
  height: 8vw;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}

.title {
  color: #00a76a;
  font-weight: 400;
  font-size: 2vw;
  font-family: "Bebas Neue" !important;
}

.location {
  color: #fff;
  font-weight: 300;
  font-family: "Open Sans";
  font-size: 1.16vw;
}

.token {
  color: #00a76a;
  font-weight: 400;
  font-size: 2vw;
  /* font-size: 1.5vw; */
  font-family: "Bebas Neue" !important;
}

.token_area {
  width: 15vw;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
  height: 6vw;
  padding: 1vw;
  font-size: 1.15vw;
  font-weight: 700;
  background-color: #212121;
  border: none;
  resize: none;
  outline: none;
  color: #fff;
}

.token_area::-webkit-scrollbar-track {
  background-color: #00a76a; /* color of the track */
}

.token_area::-webkit-scrollbar-thumb {
  background-color: #00a76a;
}

.sell_btn {
  margin-bottom: auto;
  margin-top: auto;
  background-color: #00a76a;
  font-family: "Bebas Neue" !important;
  color: #fff;
  font-size: 1.15vw;
  font-weight: 700;
  width: 7vw;
  height: 3vw;
  border: none;
}

.tokens {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
}

@media only screen and (max-width: 777px) {
  .title,
  .token {
    font-size: 2.6vw;
  }

  .sell_btn {
    font-size: 1.8vw;
  }

  .location {
    font-size: 1.6vw;
  }

  .token_area {
    gap: 0.5vw;
  }
}
