@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@800&family=Open+Sans:wght@700&display=swap");

.filterList {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  transition: all 0.5s ease;
}

.filter_btn {
  border: none;
  padding: 0.6rem 1.6rem;
  font-family: "Bebas Neue";
  font-weight: 400;
  font-size: 1.4rem;
  color: #fff;
  background-color: #151515;
  cursor: pointer;
  transition: 0.4s ease all;
}

.active {
  border: none;
  padding: 0.6rem 1.6rem;
  font-family: "Bebas Neue";
  font-weight: 400;
  font-size: 1.4rem;
  color: #fff;
  background-color: #00a76a;
  cursor: pointer;
}

.filter_btn:hover {
  background: #00a76a;
  box-shadow: 0px 0px 10px 1px rgba(0, 167, 106, 0.5);
}

@media only screen and (max-width: 700px) {
  .filterList {
    display: none;
  }
}

/* @media only screen and (max-width: 1024px) and (min-width: 451px) {
  .filterList {
    gap: 1rem;
  }
  .filter_btn {
    font-size: 1.4rem;
  }
} */
